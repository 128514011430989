import { Action, Reducer } from "redux";
import { RequestState } from "../sharedTypes";
import { AuditState } from "./state";
import { KnownAction } from "./actions";

const unloadedState: AuditState = {
    auditSearchRequestState: RequestState.NotStarted,
    cancelAuditSearchRequestState: RequestState.NotStarted,
    auditSearchResult: undefined,
    filterAggregationRequestState: RequestState.NotStarted,
    cancelFilterAggregationRequestState: RequestState.NotStarted
};

export const reducer: Reducer<AuditState> =
    (state: AuditState | undefined, incomingAction: Action):
    AuditState => {
        if (state === undefined) {
            return unloadedState;
        }

        const action = incomingAction as KnownAction;
        switch (action.type) {
            case "REQUEST_SEARCH_AUDITS":
                return {
                    ...state,
                    auditSearchRequest: action.searchRequest,
                    auditSearchRequestState: RequestState.InProgress,
                    cancelAuditSearchRequestState: RequestState.NotStarted
                };

            case "RECEIVE_SEARCH_AUDITS":
                return {
                    ...state,
                    auditSearchRequestState: RequestState.Succeeded,
                    auditSearchResult: {
                        total: action.searchResult?.total ?? 0,
                        // converting from protobuf to Date
                        items: action.searchResult?.items.map(item => ({
                            ...item,
                            timestamp: "seconds" in item.timestamp ?
                                new Date(item.timestamp.seconds * 1000)  // Convert seconds to milliseconds
                                : item.timestamp
                        })) ?? []
                    }
                };

            case "RECEIVE_AUDIT_AGGREGATION":
                return {
                    ...state,
                    auditSearchRequestState: RequestState.Succeeded,
                    auditSearchResult: {
                        items: state.auditSearchResult ? state.auditSearchResult.items : [],
                        total: state.auditSearchResult ? state.auditSearchResult.total : 0,
                        aggregation: action.searchResult.aggregation ?? []
                    }
                };

            case "REJECT_SEARCH_AUDITS":
                return {
                    ...state,
                    auditSearchRequestState: RequestState.Failed
                };

            case "REQUEST_FILTER_AGGREGATION":
                return {
                    ...state,
                    filterAggregationRequestState: RequestState.InProgress,
                    cancelFilterAggregationRequestState: RequestState.NotStarted
                };

            case "RECEIVE_FILTER_AGGREGATION":
                return {
                    ...state,
                    filterAggregationRequestState: RequestState.Succeeded,
                    filterAggregationResult: {
                        ...action.searchResult,
                        items: state.filterAggregationResult
                            ? state.filterAggregationResult?.items.filter(response => response.filterType !== action.filterType)
                                .concat(action.searchResult.items)
                            : action.searchResult.items
                    }
                };

            case "REJECT_FILTER_AGGREGATION":
                return {
                    ...state,
                    filterAggregationRequestState: RequestState.Failed
                };

            default: return state;
        }
    };
