import React, { useEffect, useRef, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";

export interface DataTableFilterSelectorProps {
    field: string;
    values: string[];
    selected: string[];
    disableButton: boolean;
    addFilter: (field: string, value: string) => void;
    removeFilter: (field: string, value: string) => void;
    display?: string;
}

export const DataTableFilterSelector = (props: DataTableFilterSelectorProps) => {
    const [show, setShow] = useState<boolean>(false);

    const dropDown = useRef<HTMLDivElement>(null);
    const field = useRef<HTMLButtonElement>(null);

    const hideDropDownTimeout = useRef<number>(0);

    const fieldClickHandler = () => {
        if (!show) {
            field.current?.addEventListener("focusout", fieldFocusHandler);
            dropDown.current?.addEventListener("focusout", fieldFocusHandler);
        }
        setShow(!show);
    };

    const fieldFocusHandler = () => {
        // this handler runs before div focus handler by convention.
        // since we don't want 'show' to be false if the other focus handler is called, 100 ms delay is necessary
        // so the other handler can clear the timeout
        const timeoutId = window.setTimeout(() => {
            setShow(false);
            field.current?.removeEventListener("focusout", fieldFocusHandler);
            dropDown.current?.removeEventListener("focusout", fieldFocusHandler);
        }, 100);
        hideDropDownTimeout.current = timeoutId;
    };

    const divFocusHandler = () => {
        clearTimeout(hideDropDownTimeout.current);
    };

    return (
        <div
            className="m-2 dropdown"
            style={{ maxWidth: 150 }}
            onFocus={divFocusHandler}>
            <button
                ref={field}
                disabled={props.disableButton}
                className="btn btn-outline-secondary dropdown-toggle"
                onClick={fieldClickHandler}>
                <span className="badge badge-pill badge-secondary mr-2">
                    {props.selected.length}
                </span>
                {props.display ?? props.field}
            </button>
            <div className={`dropdown-menu ${show ? "d-block" : "d-none"}`} ref={dropDown}>
                {props.values.map(value =>
                    <button
                        key={value}
                        className="dropdown-item"
                        type="button"
                        onClick={() => (props.selected.includes(value) ? props.removeFilter(props.field, value) : props.addFilter(props.field, value))}>
                        {props.selected.includes(value) && <AiOutlineCheckCircle className="my-auto mr-1" />}
                        {value}
                    </button>
                )}
            </div>
        </div>
    );
};

export default DataTableFilterSelector;