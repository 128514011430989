import * as React from "react";
import { confirmAlert } from "react-confirm-alert";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { FaEdit, FaPlus, FaTrash, FaRedo } from "react-icons/fa";
import { connect } from "react-redux";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { ApplicationState } from "../../store";
import { IAuthProps } from "../../store/authTypes";
import {
    ConfirmAlert,
    MessageBox,
    MessageBoxType,
    Page,
    PermissionDeniedPage,
    Spinner,
    withAuthProps,
    withCommonProps
} from "../Common";
import { RequestState } from "../../store/sharedTypes";
import { UncontrolledTooltip } from "reactstrap";
import { maybePluralize } from "../../utils";
import { PrivateLabelListItem, PrivateLabelSearchState } from "../../store/PrivateLabels/PrivateLabelsList/state";
import { actionCreators } from "../../store/PrivateLabels/PrivateLabelsList/actionCreators";
import { DefaultSettings } from "../../store/PrivateLabels/PrivateLabelsList/reducer";
import { getDataTableTheme } from "../../DataTableThemes";

type PrivateLabelsListPageProps =
    PrivateLabelSearchState &
    typeof actionCreators &
    IAuthProps &
    RouteComponentProps;

const PrivateLabelsListPage = (props: PrivateLabelsListPageProps) => {
    // Ensure data is fetched
    const shouldFetchData = props.searchRequestState !== RequestState.Failed
        && props.searchRequestState !== RequestState.InProgress
        && props.isAuthenticated;

    if (shouldFetchData) {
        if (!props.searchResult) {
            props.searchPrivateLabels();
        }
    }

    // Component state
    const [selectedPrivateLabels, setSelectedPrivateLabels] = React.useState([] as PrivateLabelListItem[]);
    const [clearRows, setClearRows] = React.useState(false);
    const pageTitle = "Private Labels";

    if (!props.isAdmin) {
        return <PermissionDeniedPage pageTitle={pageTitle} history={props.history} />;
    }

    const createPrivateLabelButton =
        <NavLink
            className="btn btn-primary btn-sm mb-3"
            to="/privateLabels/new/">
            <FaPlus className="mt-n1" /> Create new
        </NavLink>;

    const refreshListButton =
        <NavLink
            className="btn btn-primary btn-sm mb-3"
            to="#"
            onClick={e => {
                e.preventDefault();
                props.searchPrivateLabels();
            }}>
            <FaRedo className="mt-n1" /> Refresh
        </NavLink>;

    const contextComponent = (
        <>
            {maybePluralize(selectedPrivateLabels.length, "private label", "s")} selected
            <button
                className="btn btn-danger btn-sm ml-2"
                disabled={selectedPrivateLabels.length === 0}
                onClick={e => {
                    e.preventDefault();
                    confirmAlert({
                        customUI: ({ onClose }) => <ConfirmAlert
                            title="Are you sure?"
                            description={`You are about to permanently delete ${maybePluralize(selectedPrivateLabels.length, "private label", "s")}!`}
                            onCancel={onClose}
                            onConfirm={() => {
                                props.deletePrivateLabels(selectedPrivateLabels.map(u => u.id));
                                onClose();
                                setClearRows(true);
                            }}
                            confirmText="Yes, I am sure"
                            cancelText="Cancel" />
                    });
                }}><FaTrash className="mt-n1" /> Delete</button>
        </>);

    const columns: IDataTableColumn[] = [
        { name: "Name", selector: "name", sortable: false, maxWidth: "150px" },
        { name: "Display Name", selector: "displayName", sortable: false, maxWidth: "250px" },
        { name: "Email", selector: "email", sortable: false, maxWidth: "250px" },
        { name: "App Identifier", selector: "appIdentifier", sortable: false, maxWidth: "250px" },
        { name: "Admin Tool Title", selector: "adminToolTitle", sortable: false, maxWidth: "500px" },
        {
            name: "Actions",
            cell: (privateLabel: PrivateLabelListItem) => (

                <div className="w-100">
                    <NavLink
                        id={`edit-${privateLabel.id}`}
                        className={"btn shadow-none text-dark btn-sm mt-n1"}
                        to={`/privateLabels/edit/${privateLabel.id}`}                            >
                        <FaEdit size={15} />
                    </NavLink>
                    <UncontrolledTooltip
                        placement="top"
                        target={`edit-${privateLabel.id}`}>Edit {privateLabel.name}
                    </UncontrolledTooltip>

                    <button
                        id={`delete-${privateLabel.id}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        type="button"
                        disabled={props.deleteRequestState === RequestState.InProgress}
                        onClick={e => {
                            e.preventDefault();
                            confirmAlert({
                                customUI: ({ onClose }) => <ConfirmAlert
                                    title="Are you sure?"
                                    description={`Are you sure you want to permanently delete private label ${privateLabel.name}?`}
                                    onCancel={onClose}
                                    onConfirm={() => {
                                        props.deletePrivateLabel(privateLabel.id);
                                        onClose();
                                    }}
                                    confirmText="Yes, I am sure"
                                    cancelText="Cancel" />
                            });
                        }}>
                        <FaTrash size={15} />
                    </button>
                    <UncontrolledTooltip
                        placement="top"
                        target={`delete-${privateLabel.id}`}>Delete {privateLabel.name}</UncontrolledTooltip>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            maxWidth: "50px"
        }
    ];

    return (
        <Page title={pageTitle} id="search-private-labels-page">
            {createPrivateLabelButton}
            <span className="ml-1"></span>
            {refreshListButton}
            {props.deleteRequestState === RequestState.Failed &&
                <MessageBox
                    type={MessageBoxType.Error} className="mb-3" title="Failed to delete"
                    description={props.deleteError ?? "An error occurred when deleting private labels."} />}
            {props.searchRequestState === RequestState.Failed ? (
                <MessageBox
                    type={MessageBoxType.Error}
                    title="Failed to load data"
                    description="An error occurred when loading private labels.">
                    <button
                        className="btn btn-primary mt-3"
                        onClick={() => props.searchPrivateLabels()}>Try again</button>
                    <button
                        className="btn btn-primary mt-3 ml-3"
                        onClick={() => {
                            props.changePrivateLabelSearchRequest({
                                limit: DefaultSettings.defaultPageSize,
                                offset: DefaultSettings.defaultPageNumber * DefaultSettings.defaultPageSize,
                            });
                            props.searchPrivateLabels();
                        }}>Refresh</button>
                </MessageBox>
            ) :
                (<DataTable
                    // Options
                    keyField={"id"}
                    noHeader={!props.searchResult}
                    title={!props.searchResult ? null : `Found ${maybePluralize(props.searchResult.total, "private label", "s")}`}
                    columns={columns}
                    data={!props.searchResult ? [] : props.searchResult.items}
                    noDataComponent={<p> </p>}

                    // Pagination
                    pagination
                    paginationServer
                    paginationPerPage={props.searchRequest!.limit}
                    paginationRowsPerPageOptions={DefaultSettings.paginationRowsPerPageOptions}
                    paginationTotalRows={!props.searchResult ? 0 : props.searchResult.total}
                    onChangeRowsPerPage={size => {
                        props.changePrivateLabelSearchRequest({ limit: size });
                        props.searchPrivateLabels();
                    }}
                    // page index in API is zero based but in react-data-table-component it starts from 1
                    onChangePage={page => {
                        props.changePrivateLabelSearchRequest({ offset: (page - 1) * (props!.searchRequest!.limit || DefaultSettings.defaultPageSize) });
                        props.searchPrivateLabels();
                    }}

                    // Sorting
                    sortServer

                    // Selectable rows
                    clearSelectedRows={clearRows}
                    onSelectedRowsChange={({ selectedRows }) => {
                        setSelectedPrivateLabels(selectedRows);
                        setClearRows(false);
                    }}
                    selectableRows
                    selectableRowsHighlight
                    // Loading/progress
                    progressPending={props.searchRequestState === RequestState.InProgress || props.deleteRequestState === RequestState.InProgress}
                    progressComponent={<Spinner />}

                    // Actions
                    contextComponent={contextComponent}

                    // Theme
                    theme={getDataTableTheme()}
                />)
            }
        </Page>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => state.privateLabelsList,
    actionCreators
)(PrivateLabelsListPage as any)));
