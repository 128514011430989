import { callApiGateway } from "../../ApiGateway";
import { AppThunkAction } from "../index";
import { KnownAction } from "./actions";
import { CountryLookupItem } from "./state";
import { PrivateLabelSearchRequest, PrivateLabelSearchSearchResponse } from "../PrivateLabels/PrivateLabelsList/state";
import { DistributorSearchRequest, DistributorSearchSearchResponse } from "../Distributors/DistributorsList/state";

export const actionCreators = {
    /**
     * Lookup countries.
     */
    lookupCountries: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "REQUEST_COUNTRIES_LOOKUP" });
        callApiGateway<{}, CountryLookupItem[]>("lookups/countries")
            .then(response => dispatch({ type: "RECEIVE_COUNTRIES_LOOKUP", countries: response }))
            .catch(() => dispatch({ type: "REJECT_COUNTRIES_LOOKUP" }));
    },

    /**
     * Lookup private labels.
     */
    lookupPrivateLabels: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // TODO: should we have scrolling search for dropdown or we would have just several private labels in real life scenario?
        const request: PrivateLabelSearchRequest = {
            offset: 0,
            limit: 1000
        };

        dispatch({ type: "REQUEST_PRIVATE_LABELS_LOOKUP" });
        callApiGateway<{}, PrivateLabelSearchSearchResponse>("privateLabels/search", "POST", request)
            .then(response => dispatch({
                type: "RECEIVE_PRIVATE_LABELS_LOOKUP",
                privateLabels: response.items
            }))
            .catch(() => dispatch({ type: "REJECT_PRIVATE_LABELS_LOOKUP" }));
    },

    /**
     * Lookup distributors.
     */
    lookupDistributors: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        // TODO: should we have scrolling search for dropdown or we would have just several private labels in real life scenario?
        const request: DistributorSearchRequest = {
            offset: 0,
            limit: 1000
        };

        dispatch({ type: "REQUEST_DISTRIBUTORS_LOOKUP" });
        callApiGateway<{}, DistributorSearchSearchResponse>("distributors/search", "POST", request)
            .then(response => dispatch({
                type: "RECEIVE_DISTRIBUTORS_LOOKUP",
                distributors: response.items.map(x => ({ id: x.id, name: x.customerName }))
            }))
            .catch(() => dispatch({ type: "REJECT_DISTRIBUTORS_LOOKUP" }));
    },

    setWidth: (size: number): AppThunkAction<KnownAction> => (dispatch) => dispatch({ type: "SET_SIZE", size })
};
