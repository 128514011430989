/* Search */
export interface SearchRequest {
    query: string;
    filter: FilterSettings;
    order: OrderSettings;
    page: PagingSettings;
}

export interface BulkSearchRequest extends SearchRequest {
    ids: string[];
}

/* Filter */
export enum FilterTermMatchType {
    Contains = 0,
    NotContains = 1,
    Equals = 2,
    NotEquals = 3,
    GreaterThan = 4,
    GreaterThanOrEquals = 5,
    LessThan = 6,
    LessThanOrEquals = 7
}

export enum FilterClauseType {
    And = 0,
    Or = 1
}

export interface FilterTermMatch {
    field: string;
    value: string;
    type: FilterTermMatchType
}

export interface FilterSettings {
    terms: FilterTermMatch[],
    clause: FilterClauseType
}

export interface IntervalSettings {
    from?: Date,
    to?: Date
}

/* Order */
export interface OrderingTerm {
    field: string,
    desc: boolean
}

export interface OrderSettings {
    terms: OrderingTerm[]
}

/* Paging */
export interface PagingSettings {
    size: number;
    number: number;
}

/* Response */
export interface SearchResponse<T> {
    items: T[];
    total: number;
}

export const defaultSearchRequest: SearchRequest = {
    query: "",
    filter: {
        terms: [],
        clause: FilterClauseType.Or
    },
    order: {
        terms: []
    },
    page: {
        size: 50,
        number: 0
    },
};

export default SearchRequest;