export interface FilterAggregationResponse {
    total: number;
    items: AggregationBucket[];
}

export interface AggregationBucket {
    filterType?: AuditFilters;
    value: string,
    valueCount: number,
}

export enum AuditFilters {
    Level = "Level",
    Category = "Category",
    Unknown = "Unknown"
}