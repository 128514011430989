import * as React from "react";
import { Authentication, BleState } from "../../store/ThermostatDetails/state";
import { formatUTCDate, undefNullCheck } from "../../utils";
import { ThermostatDetailsProps } from "./ThermostatDetailsPage";

export const WifiSettingsTab = (props: ThermostatDetailsProps) => {

    return (
        <div className="card">
            <div className="card-header p-1 pl-3">
                <b>WiFi settings</b>
            </div>
            <div className="card-body py-2">
                <table className="table table-sm text-break">
                    <tbody>
                        <tr>
                            <th scope="row">SSID:</th>
                            <td>{undefNullCheck(props.thermostat?.settings?.wifi?.ssid)}</td>
                        </tr>
                        <tr>
                            <th scope="row">Authentication:</th>
                            <td>{props.thermostat?.settings?.wifi?.authentication !== undefined ?
                                Authentication[props.thermostat?.settings?.wifi?.authentication] : "No data"}</td>
                        </tr>
                        <tr>
                            <th scope="row">Default gateway:</th>
                            <td>{undefNullCheck(props.thermostat?.settings?.wifi?.defaultGateway)}</td>
                        </tr>
                        <tr>
                            <th scope="row">Netmask:</th>
                            <td>{undefNullCheck(props.thermostat?.settings?.wifi?.netmask)}</td>
                        </tr>
                        <tr>
                            <th scope="row">IPv4:</th>
                            <td>{undefNullCheck(props.thermostat?.settings?.wifi?.ipv4)}</td>
                        </tr>
                        <tr>
                            <th scope="row">IPv6:</th>
                            <td>{undefNullCheck(props.thermostat?.settings?.wifi?.ipv6)}</td>
                        </tr>
                        <tr>
                            <th scope="row">DHCP:</th>
                            <td>{props.thermostat?.settings?.wifi?.dhcp !== undefined ?
                                props.thermostat?.settings?.wifi?.dhcp ? "Enabled" : "Disabled" : "No data"}</td>
                        </tr>
                        <tr>
                            <th scope="row">MAC WiFI:</th>
                            <td>{undefNullCheck(props.thermostat?.settings?.wifi?.macWifi)}</td>
                        </tr>
                        <tr>
                            <th scope="row">MAC BLE:</th>
                            <td>{undefNullCheck(props.thermostat?.settings?.wifi?.macBle)}</td>
                        </tr>
                        <tr>
                            <th scope="row">Preferred WiFi channel:</th>
                            <td>{undefNullCheck(props.thermostat?.settings?.wifi?.preferedWifiChannel)}</td>
                        </tr>
                        <tr>
                            <th scope="row">Number of WiFi AP Connect calls:</th>
                            <td>{undefNullCheck(props.thermostat?.settings?.wifi?.numberOfTimesWifiApConnectWasCalled)}</td>
                        </tr>
                        <tr>
                            <th scope="row">BLE State:</th>
                            <td>{props.thermostat?.settings?.wifi?.bleState !== undefined ?
                                BleState[props.thermostat?.settings?.wifi?.bleState] : "No data"}</td>
                        </tr>
                        <tr>
                            <th scope="row">Changed:</th>
                            <td>{props.thermostat?.settings?.wifi?.changeTimestamp !== undefined &&
                                props.thermostat?.settings?.wifi?.changeTimestamp !== null ?
                                formatUTCDate(props.thermostat?.settings?.wifi?.changeTimestamp) : "No data"}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};
