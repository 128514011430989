import * as React from "react";
import { distributorLabelFormat, ThermostatDetailsProps } from "./ThermostatDetailsPage";
import { UncontrolledTooltip } from "reactstrap";
import { formatUTCDate, secondsToDuration, isValidGuid, undefNullCheck, printTemperature } from "../../utils";
import { FaExternalLinkAlt } from "react-icons/fa";

export const InformationTab = (props: ThermostatDetailsProps) => {

    return (
        <div>
            <div className="card">
                <div className="card-header p-1 pl-3">
                    <b>General</b>
                </div>
                <div className="card-body py-2">
                    <table className="table table-sm text-break">
                        <tbody>
                            <tr>
                                <th scope="row">ID:</th>
                                <td>{undefNullCheck(props.thermostat?.id)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Serial Number:</th>
                                <td>{undefNullCheck(props.thermostat?.serialNumber)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Name:</th>
                                <td>{undefNullCheck(props.thermostat?.name)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Created date:</th>
                                <td>{props.thermostat?.createdDate !== undefined &&
                                    props.thermostat?.createdDate !== null ?
                                    formatUTCDate(props.thermostat?.createdDate) : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Distributor:</th>
                                <td>{props.thermostat !== undefined ? distributorLabelFormat(props) : "No data"}</td>
                            </tr>

                            <tr>
                                <th scope="row">Zone Name:</th>
                                <td>{undefNullCheck(props.thermostat?.zoneName)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Building Name:</th>
                                <td>{undefNullCheck(props.thermostat?.buildingName)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Email:</th>
                                <td>
                                    {!props.thermostatOwner || !isValidGuid(props.thermostatOwner.userId) ? "No data" :
                                        <>
                                            {props.thermostatOwner.email}
                                            <a id="user-see-more" className="ml-2" href={`/user-info/${props.thermostatOwner.userId}`}>
                                                <button className="btn btn-primary m-0" style={{ padding: "0px 0.4rem", borderTop: "0px", verticalAlign: "unset" }}>
                                                    <FaExternalLinkAlt size={12} className="mt-n1" />
                                                </button>
                                            </a>
                                            <UncontrolledTooltip placement="right" target="user-see-more">Go to user</UncontrolledTooltip>
                                        </>}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card">
                <div className="card-header p-1 pl-3">
                    <b>Device information</b>
                </div>
                <div className="card-body py-2">
                    <table className="table table-sm text-break">
                        <tbody>
                            <tr>
                                <th scope="row">Software version:</th>
                                <td>{undefNullCheck(props.thermostat?.deviceInformation?.thermostatSoftwareVersion)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Hardware version:</th>
                                <td>{undefNullCheck(props.thermostat?.deviceInformation?.thermostatHardwareVersion)}</td>
                            </tr>
                            <tr>
                                <th scope="row">WiFi software version:</th>
                                <td>{undefNullCheck(props.thermostat?.deviceInformation?.wifiSoftwareVersion)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card">
                <div className="card-header p-1 pl-3">
                    <b>Warranty information</b>
                </div>
                <div className="card-body py-2">
                    <table className="table table-sm text-break">
                        <tbody>
                            <tr>
                                <th scope="row">Total uptime:</th>
                                <td>{undefNullCheck(secondsToDuration(props.thermostat?.deviceInformation?.warrantyInfo?.totalUptimeS))}</td>
                            </tr>
                            <tr>
                                <th scope="row">Relay active:</th>
                                <td>{undefNullCheck(secondsToDuration(props.thermostat?.deviceInformation?.warrantyInfo?.relayActiveS))}</td>
                            </tr>
                            <tr>
                                <th scope="row">Relay clicks:</th>
                                <td>{undefNullCheck(props.thermostat?.deviceInformation?.warrantyInfo?.relayClicks)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Overheat time:</th>
                                <td>{undefNullCheck(secondsToDuration(props.thermostat?.deviceInformation?.warrantyInfo?.overheatTimeS))}</td>
                            </tr>
                            <tr>
                                <th scope="row">Max. internal temperature:</th>
                                <td>{printTemperature(props.thermostat?.deviceInformation?.warrantyInfo?.maxInternalTemp)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Min. internal temperature:</th>
                                <td>{printTemperature(props.thermostat?.deviceInformation?.warrantyInfo?.minInternalTemp)}</td>
                            </tr>
                            <tr>
                                <th scope="row">GFCI count:</th>
                                <td>{undefNullCheck(props.thermostat?.deviceInformation?.warrantyInfo?.gfciCount)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Seconds since last GFCI:</th>
                                <td>{undefNullCheck(secondsToDuration(props.thermostat?.deviceInformation?.warrantyInfo?.secSinceLastGfci))}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
