import React from "react";

export const chipColors = ["RoyalBlue", "orange", "Tomato", "black"];
export const Chip = (props: {text: string, severity: number}) => {
    const findChipColor = (idx: number) => {
        return idx >= 0 && idx < chipColors.length ? chipColors[idx] : "grey";
    };

    const levelChip : React.CSSProperties = {
        minWidth: "100px",
        padding: "5px",
        borderRadius: "10px",
        backgroundColor: findChipColor(props.severity),
        color: "white",
        textAlign: "center"
    };

    return <span style={levelChip}>{props.text}</span>;
};