import { Formik } from "formik";
import * as React from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { FaFileMedicalAlt, FaTrash, FaSlidersH, FaUnlink, FaUserAlt, FaSearch } from "react-icons/fa";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { NavLink } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { ApplicationState } from "../../store";
import { IAuthProps } from "../../store/authTypes";
import { RequestState } from "../../store/sharedTypes";
import { actionCreators } from "../../store/ThermostatsList/actionCreators";
import { DefaultSettings } from "../../store/ThermostatsList/reducer";
import {
    SearchFilterOperation,
    ThermostatListItem,
    ThermostatsListState,
    ThermostatsSearchRequest
} from "../../store/ThermostatsList/state";
import { isValidGuid, maybePluralize } from "../../utils";
import { ConfirmAlert, MessageBox, MessageBoxType, Page, Spinner, withAuthProps, withCommonProps } from "../Common";
import { getDataTableTheme } from "../../DataTableThemes";
import { CommonState } from "../../store/Common/state";
import { DataTableFilterSelectorDetails } from "../Common/SearchableDataTable/SearchableDataTable";
import DataTableFilterSelector from "../Common/SearchableDataTable/DataTableFilterSelector";
import useResizeObserver from "@react-hook/resize-observer";
import { DualScrollBarContainer, scrollBreakPointXL } from "../Common/DualScrollbarContainer";

const isEqual = require("react-fast-compare");

type ThermostatsListProps =
    ThermostatsListState &
    typeof actionCreators &
    RouteComponentProps<{ query?: string }> &
    IAuthProps &
    CommonState;

const ThermostatsList = (props: ThermostatsListProps) => {
    // Ensure data is fetched
    const shouldFetchData = props.searchRequestState !== RequestState.Failed
        && props.searchRequestState !== RequestState.InProgress
        && props.isAuthenticated;

    if (shouldFetchData) {
        const query = props.match.params.query;

        if (!!query && props.thermostatsSearchRequest!.query !== query) {
            props.changeThermostatsSearchRequest({ query: query });
            props.loadThermostats();
            props.history.push("/thermostats");
        } else if (!props.thermostatsResult) {
            props.loadThermostats();
        }
    }

    const { unlinkThermostatState, deleteThermostatState, loadThermostats } = props;
    React.useEffect(() => {
        if (unlinkThermostatState === RequestState.Succeeded || deleteThermostatState === RequestState.Succeeded) {
            loadThermostats();
        }
    }, [unlinkThermostatState, deleteThermostatState, loadThermostats]);

    // Search form
    const SearchForm = () => (
        <Formik
            onSubmit={values => {
                const valuesChangedFromInit = !isEqual(
                    props.thermostatsSearchFormValues,
                    values);

                const newRequest: ThermostatsSearchRequest = {
                    query: values.query,
                    filters: []
                };

                // Set filters
                if (!!values.isOnline) {
                    newRequest.filters!.push({ field: "isOnline", value: values.isOnline, operation: SearchFilterOperation.AND });
                }

                // reset page number to first page if filter was changed
                // TODO: for some reason DataTable does not react to this.props.paging.* changes properly, investigate why is that so
                if (valuesChangedFromInit) {
                    newRequest.offset = DefaultSettings.defaultPageNumber;
                }

                props.changeThermostatsSearchRequest(newRequest);
                props.loadThermostats();
            }}
            validateOnChange
            initialValues={props.thermostatsSearchFormValues}>
            {({
                values,
                dirty,
                handleChange,
                handleSubmit
            }) =>
                <form onSubmit={handleSubmit}>
                    <div className="input-group mb-3" style={{ maxWidth: "600px" }}>
                        {/* Search field */}
                        <input
                            type="text"
                            name="query"
                            value={values.query}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Search thermostats" />

                        <div className="input-group-append">
                            {/* Search button */}
                            <button
                                disabled={props.searchRequestState === RequestState.InProgress}
                                id="search-btn"
                                type="submit"
                                className="btn btn-primary">
                                <FaSearch className="mt-n1" />
                            </button>
                            <UncontrolledTooltip placement="top" target="search-btn">Search</UncontrolledTooltip>
                        </div>
                    </div>
                </form>
            }
        </Formik>
    );

    /**
     * Map column name to field name used to sort.
     */
    const getOrderName = (columnName: string): string => {
        switch (columnName) {
            case "isConnected":
                return "isOnline";

            // TODO: Add map when this has been implemented.
            case "isLinked":
                return "UserId";

            case "email":
                return "userEmail";

            case "softwareVersion":
                return "thermostatSoftwareVersion";

            case "actualTemperature":
                return "currentTemperature ";
        }
        return columnName;
    };

    const getFieldName = (fieldName: string) : string => {
        switch (fieldName) {
            case "Connection": return "isOnline";
            case "Link": return "isLinked";
        }
        return fieldName;
    };

    const connectionStatus = ["Connected", "Disconnected"];
    const linkStatus = ["Linked", "Unlinked"];

    const filters: DataTableFilterSelectorDetails[] = [{
        field: "Connection",
        values: connectionStatus
    },
    {
        field: "Link",
        values: linkStatus
    }];

    /**
     * Create columns for data table.
     */
    const createColumns = () => {
        const statusFormat = (thermostat: ThermostatListItem): string => {
            return connectionStatus[thermostat.isConnected ? 0 : 1];
        };

        const linkFormat = (thermostat: ThermostatListItem): string => {
            return linkStatus[thermostat.isLinked ? 0 : 1];
        };

        const temperatureFormat = (temperature: number): string => {
            const number = Number(temperature).toFixed(2);
            return Intl.NumberFormat().format(Number(number));
        };

        const privateLabelFormat = (thermostat: ThermostatListItem): string => {
            // TODO: should we add name and ability to search/sort by name to thermostat service?
            return props.privateLabels?.find(x => x.id === thermostat.privateLabelId)?.name || "N/A";
        };

        const distributorLabelFormat = (thermostat: ThermostatListItem): string => {
            // TODO: should we add name and ability to search/sort by name to thermostat service?
            return props.distributors?.find(x => x.id === thermostat.internalDistributorId)?.name || "N/A";
        };

        const renderThermostatActionsCell = (thermostat: ThermostatListItem) => {

            const unlinkConfirmationText =
                <>
                    Do you want to unlink the thermostat "{thermostat.serialNumber}"?<br />
                    Thermostat won't be able to connect to cloud!
                </>;

            const handleUnlinkButtonClicked = () => {
                confirmAlert({
                    customUI: ({ onClose }) => <ConfirmAlert
                        title="Are you sure?"
                        description={unlinkConfirmationText}
                        onCancel={onClose}
                        onConfirm={() => {
                            if (thermostat.isLinked) {
                                props.unlinkThermostat(thermostat.id);
                            } else {
                                alert("Thermostat is not linked");
                            }

                            onClose();
                        }}
                        confirmText={"Yes, unlink it!"}
                        cancelText="Cancel" />
                });
            };

            const deleteConfirmationText =
                <>
                    Do you want to delete thermostat "${thermostat.serialNumber}"?<br />
                    This action completely deletes all thermostat data.<br />
                    <b>THIS ACTION IS IRREVERSIBLE!</b>
                </>;

            const handleDeleteButtonClicked = () => {
                confirmAlert({
                    customUI: ({ onClose }) => <ConfirmAlert
                        title="Are you sure?"
                        description={deleteConfirmationText}
                        onCancel={onClose}
                        onConfirm={() => {
                            if (thermostat.isLinked) {
                                alert("Thermostat is linked and can't be deleted");
                            } else {
                                props.deleteThermostat(thermostat.id);
                            }

                            onClose();
                        }}
                        confirmText={"Yes, delete it!"}
                        cancelText="Cancel" />
                });
            };

            return (
                <div className="d-flex flex-row">
                    <NavLink
                        id={`details-${thermostat.id}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        to={`/thermostat/${thermostat.id}`}>
                        <FaSlidersH size={15} />
                    </NavLink>
                    <UncontrolledTooltip placement="top" target={`details-${thermostat.id}`}>View Thermostat Settings</UncontrolledTooltip>

                    {isValidGuid(thermostat.userId) ?
                        <>
                            <NavLink
                                id={`owner-${thermostat.id}`}
                                className="btn shadow-none text-dark btn-sm mt-n1"
                                to={`/users/edit/${thermostat.userId}`}>
                                <FaUserAlt size={15} />
                            </NavLink>
                            <UncontrolledTooltip placement="top" target={`owner-${thermostat.id}`}>See Associated User Account</UncontrolledTooltip>
                        </> :
                        <>
                            <div
                                id={`no-owner-${thermostat.id}`}
                                className="btn shadow-none text-dark btn-sm mt-n1">
                                <FaUserAlt size={15} color="grey" />
                            </div>
                            <UncontrolledTooltip placement="top" target={`no-owner-${thermostat.id}`}>No User Associated</UncontrolledTooltip>
                        </>
                    }
                    <NavLink
                        id={`audit-${thermostat.id}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        to={`/thermostat/${thermostat.id}/audits`}>
                        <FaFileMedicalAlt size={15} />
                    </NavLink>
                    <UncontrolledTooltip placement="top" target={`audit-${thermostat.id}`}>See Audit Logs</UncontrolledTooltip>

                    {
                        thermostat.isLinked ?
                            <>
                                <button
                                    id={`unlink-${thermostat.id}`}
                                    className="btn shadow-none text-dark btn-sm mt-n1"
                                    type="button"
                                    disabled={props.unlinkThermostatState === RequestState.InProgress}
                                    onClick={handleUnlinkButtonClicked}>
                                    <FaUnlink size={15} />
                                </button>
                                <UncontrolledTooltip placement="top" target={`unlink-${thermostat.id}`}>Unlink Thermostat</UncontrolledTooltip>
                            </> :
                            <>
                                <button
                                    id={`delete-${thermostat.id}`}
                                    className="btn shadow-none text-dark btn-sm mt-n1"
                                    type="button"
                                    disabled={props.deleteThermostatState === RequestState.InProgress}
                                    onClick={handleDeleteButtonClicked}>
                                    <FaTrash size={15} />
                                </button>
                                <UncontrolledTooltip placement="top" target={`delete-${thermostat.id}`}>Delete Thermostat</UncontrolledTooltip>
                            </>
                    }
                </div>
            );
        };

        const columns: IDataTableColumn[] = [
            {
                name: "Name",
                selector: "name",
                sortable: true,
                compact: true
            },
            {
                name: "WiFi",
                selector: "isConnected",
                format: statusFormat,
                sortable: true,
                compact: true,
                maxWidth: "110px"
            },
            {
                name: "Linked",
                selector: "isLinked",
                format: linkFormat,
                sortable: true,
                compact: true,
                maxWidth: "110px"
            },
            {
                name: "Email",
                selector: "email",
                sortable: true,
                compact: true
            },
            {
                name: "Serial No.",
                selector: "serialNumber",
                sortable: true,
                compact: true,
                maxWidth: "100px"
            },
            {
                name: "SW Version",
                selector: "softwareVersion",
                sortable: true,
                compact: true,
                maxWidth: "100px"
            },
            {
                name: "Actions",
                cell: renderThermostatActionsCell,
                allowOverflow: true,
                button: false,
                sortable: false,
                compact: true,
                maxWidth: "135px"
            }
        ];


        // show distributor info for admin or for supporter when there are more than one distributor
        if (props.isAdmin || (!!props.distributors && props.distributors.length > 0)) {
            columns.unshift({ name: "Distributor ID", selector: "distributorId", sortable: true, maxWidth: "125px" });
            columns.unshift({ name: "Distributor", selector: "distributorId", format: distributorLabelFormat, sortable: true, maxWidth: "150px" });
        }

        if (props.isAdmin) {
            columns.unshift({
                name: "Private Label",
                selector: "privateLabelId",
                format: privateLabelFormat,
                sortable: false,
                compact: true,
                maxWidth: "50px"
            });
        }

        return columns;
    };

    const selectedFilters = (field: string) : string[] => {
        return (props.thermostatsSearchRequest?.filters || [])
            .filter(term => term.filter === field && term.key !== undefined)
            .map(term => term.key as string);
    };

    const addFilter = (field: string, value: string) => {
        const updatedSearchRequest: ThermostatsSearchRequest = {
            ...props.thermostatsSearchRequest, filters: [
                ...(props.thermostatsSearchRequest?.filters || []),
                {
                    filter: field,
                    field: getFieldName(field),
                    key: value,
                    value: (value === "Connected" || value === "Linked").toString(),

                    operation: SearchFilterOperation.AND
                }
            ]
        };
        props.changeThermostatsSearchRequest(updatedSearchRequest);
        props.loadThermostats();
    };

    const removeFilter = (field: string, value: string) => {
        const updatedSearchRequest: ThermostatsSearchRequest = {
            ...props.thermostatsSearchRequest,
            filters: props.thermostatsSearchRequest?.filters!
                .filter(term => term.filter !== field || term.key !== value)
        };
        props.changeThermostatsSearchRequest(updatedSearchRequest);
        props.loadThermostats();
    };

    // Render thermostats list
    return (
        <Page id="thermostats-page" title="Thermostats">
            <SearchForm />
            <div className="d-flex m-2" style={{ maxWidth: "800px" }}>
                {filters?.map(filter =>
                    <DataTableFilterSelector
                        key={filter.field}
                        field={filter.field}
                        selected={selectedFilters(filter.field)}
                        values={filter.values}
                        disableButton={props.searchRequestState === RequestState.InProgress}
                        addFilter={addFilter}
                        removeFilter={removeFilter}
                        display={filter.display} />
                )}
            </div>
            {props.unlinkThermostatState === RequestState.Failed ? <MessageBox type={MessageBoxType.Error} className="mb-3" title="Failed to unlink" description="An error occurred when unlinking thermostat." /> : null}
            {props.deleteThermostatState === RequestState.Failed ? <MessageBox type={MessageBoxType.Error} className="mb-3" title="Failed to delete" description="An error occurred when deleting thermostat." /> : null}
            {props.searchRequestState === RequestState.Failed ? (
                <MessageBox type={MessageBoxType.Error} title="Failed to search" description="An error occurred when searching thermostats.">
                    <button
                        className="btn btn-primary mt-3"
                        onClick={() => props.loadThermostats()}>Try again</button>
                    <button
                        className="btn btn-primary mt-3 ml-3"
                        onClick={() => {
                            props.changeThermostatsSearchRequest({
                                query: DefaultSettings.defaultThermostatsSearchFormValues.query,
                                filters: [],
                                limit: DefaultSettings.defaultPageSize,
                                offset: DefaultSettings.defaultPageNumber * DefaultSettings.defaultPageSize,
                                orderBy: DefaultSettings.defaultSortField,
                                orderDescending: !DefaultSettings.defaultSortAsc
                            });
                            props.loadThermostats();
                        }}>Reset search</button>
                </MessageBox>
            ) : <>
                <DualScrollBarContainer>
                    <DataTable
                        style={{ minWidth:scrollBreakPointXL }}
                        // Options
                        keyField={"id"}
                        noHeader={!props.thermostatsResult}
                        title={!props.thermostatsResult ? null : `Found ${maybePluralize(props.thermostatsResult.total, "thermostat")}`}
                        columns={createColumns()}
                        data={!props.thermostatsResult ? [] : props.thermostatsResult.items}
                        noDataComponent={<p></p>}

                        // Pagination
                        pagination
                        paginationServer
                        paginationPerPage={props.thermostatsSearchRequest!.limit}
                        paginationRowsPerPageOptions={DefaultSettings.paginationRowsPerPageOptions}
                        paginationTotalRows={!props.thermostatsResult ? 0 : props.thermostatsResult.total}
                        onChangeRowsPerPage={size => {
                            props.changeThermostatsSearchRequest({ ...props.thermostatsSearchRequest, limit: size });
                            props.loadThermostats();
                        }}
                        // page index in API is zero based but in react-data-table-component it starts from 1
                        onChangePage={page => {
                            props.changeThermostatsSearchRequest({ ...props.thermostatsSearchRequest, offset: (page - 1) * (props!.thermostatsSearchRequest!.limit || DefaultSettings.defaultPageSize) });
                            props.loadThermostats();
                        }}

                        // Sorting
                        defaultSortAsc={DefaultSettings.defaultSortAsc}
                        defaultSortField={DefaultSettings.defaultSortField} // without this first render doesn't show default sort column and also sort order is stuck in default
                        onSort={(column, sortDirection) => {
                            props.changeThermostatsSearchRequest({
                                ...props.thermostatsSearchRequest,
                                orderBy: getOrderName(column.selector as string),
                                orderDescending: sortDirection === "desc"
                            });
                            props.loadThermostats();
                        }}
                        sortServer

                        // Loading/progress
                        progressPending={props.searchRequestState === RequestState.InProgress || props.unlinkThermostatState === RequestState.InProgress || props.deleteThermostatState === RequestState.InProgress }
                        progressComponent={<Spinner />}

                        // Theme
                        theme={getDataTableTheme()}
                    />
                </DualScrollBarContainer>
            </>}
        </Page>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => ({ ...state.thermostatsList }),
    actionCreators
)(ThermostatsList as any)));
