import { AppThunkAction } from "../..";
import { callApiGateway } from "../../../ApiGateway";
import SearchRequest, { BulkSearchRequest } from "../../search";
import { KnownAction } from "./actions";
import { AuditFilters, FilterAggregationResponse } from "./state";

export const actionCreators = {
    loadFilterAggregations: (field: "User" | "Thermostat", ids: string[], type: AuditFilters) : AppThunkAction<KnownAction> => (dispatch) =>{
        dispatch({ type: "REQUEST_FILTER_AGGREGATION" });

        let request;
        if (ids.length === 1) {
            request = callApiGateway<SearchRequest, FilterAggregationResponse>(`elastic/${field}/${ids[0]}/aggregation/${type}`, "GET");
        } else if (ids.length > 1) {
            request = callApiGateway<SearchRequest, FilterAggregationResponse>(`elastic/${field}/aggregation/${type}`, "POST", { ids } as BulkSearchRequest);
        }

        request?.then(response => dispatch(
            {
                type: "RECEIVE_FILTER_AGGREGATION",
                searchResult: { ...response, items: response.items.map(item => ({ ...item, filterType: type })) },
                filterType: type,
            })
        ).catch((e) => dispatch({ type: "REJECT_FILTER_AGGREGATION" }));
    },
};