import * as React from "react";
import { IconContext } from "react-icons";
import { FaCog, FaUser } from "react-icons/fa";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { ApplicationState } from "../store";
import { extractAuthProps, IAuthProps } from "../store/authTypes";
import { signIn, signOut } from "../utils";
import { LogoSvg } from "./Images/LogoSvg";
import "./NavMenu.css";
import { getPrivateLabelConfig } from "../PrivateLabel";

const NavMenu = (props: IAuthProps & {width: number}) => {
    const userId = !!props.user ? props.user.profile.sub : "";
    const privateLabel = getPrivateLabelConfig();
    return (
        <IconContext.Provider value={{ className: "header-react-icons" }}>
            <header className="shadow bg-white d-flex justify-content-start px-3 py-2 d-print-none">
                <div style={{ width: props.width }} className="text-center px-2">
                    <NavLink exact to="/">
                        <LogoSvg
                            plName={privateLabel.name}
                            width={80} className="nav-brand-logo"
                        />
                    </NavLink>
                </div>
                <div className="px-3 d-flex flex-fill justify-content-between mt-1">
                    <h3 className="m-0">
                        <NavLink
                            exact
                            className="text-dark text-decoration-none"
                            to="/">{privateLabel.adminToolTitle ? privateLabel.adminToolTitle : "Support Tool"}</NavLink>
                    </h3>
                </div>
                <nav className="mt-2">
                    {props.isAuthenticated ?
                        <>
                            {!props.isAdmin ? null : (
                                <NavLink className="p-2" to="/admin-tools" title="Administrator Tools"><FaCog /></NavLink>
                            )}
                            <NavLink className="p-2" to={`/users/edit/${userId}`} title="Profile"><FaUser /></NavLink>
                            <button className="btn btn-link p-0" onClick={signOut} title="Logout">Logout</button>
                        </>
                        :
                        <>
                            <button className="btn btn-link p-0" onClick={signIn} title="Login">Login</button>
                        </>
                    }
                </nav>
            </header>
        </IconContext.Provider>
    );
};

const mapStateToProps = (state: ApplicationState) => {
    return ({
        ...extractAuthProps(state.oidc),
        width: state.common ? state.common.sideMenuWidth : 0
    });
};

export default connect(
    mapStateToProps
)(NavMenu);