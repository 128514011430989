import * as React from "react";
import { DayOfWeek } from "../../store/ThermostatDetails/state";
import {  ThermostatDetailsProps } from "./ThermostatDetailsPage";
import { getSetpoint, isAuto, modeFormat } from "../../store/ThermostatsList/state";
import { formatUTCDate, printTemperature, undefNullCheck } from "../../utils";

// eslint-disable-next-line complexity
export const StateTab = (props: ThermostatDetailsProps) => {

    return (
        <div>
            <div className="card">
                <div className="card-header p-1 pl-3">
                    <b>State</b>
                </div>
                <div className="card-body py-2">
                    <table className="table table-sm text-break">
                        <tbody>
                            <tr>
                                <th scope="row">Online:</th>
                                <td>{props.thermostat?.state?.isOnline !== undefined ?
                                    props.thermostat?.state.isOnline ? "Connected" : "Not connected" : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Away:</th>
                                <td>{props.thermostat?.state?.isAwayActive !== undefined ?
                                    props.thermostat?.state.isAwayActive ? "Yes" : "No" : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Temperature unit:</th>
                                <td>{undefNullCheck(props.thermostat?.settings.display?.temperatureUnit)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Floor temperature:</th>
                                <td>{printTemperature(props.thermostat?.state?.floorTemperature)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Room temperature:</th>
                                <td>{printTemperature(props.thermostat?.state?.roomTemperature)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Screenlock status:</th>
                                <td>{props.thermostat?.settings?.display?.screenLockEnabled !== undefined ?
                                    props.thermostat.settings.display.screenLockEnabled ? "Enabled" : "Disabled" : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Adaptive heating:</th>
                                <td>{props.thermostat?.state?.isAdaptiveHeatingActive !== undefined ?
                                    props.thermostat.state.isAdaptiveHeatingActive ? "Enabled" : "Disabled" : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Heat relay:</th>
                                <td>{props.thermostat?.state?.isHeatRelayActive !== undefined ?
                                    props.thermostat.state.isHeatRelayActive ? "Enabled" : "Disabled" : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Open window detected:</th>
                                <td>{props.thermostat?.state?.isOpenWindowDetected !== undefined ?
                                    props.thermostat.state.isOpenWindowDetected ? "Yes" : "No" : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Error flags:</th>
                                <td>{undefNullCheck(props.thermostat?.state?.errorFlags)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Original error flags:</th>
                                <td>{undefNullCheck(props.thermostat?.state?.originalErrorFlags)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Latest change:</th>
                                <td>{props.thermostat?.state?.latestChange !== undefined &&
                                    props.thermostat?.state?.latestChange !== null ?
                                    formatUTCDate(props.thermostat?.state?.latestChange) : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Last state reported at:</th>
                                <td>{props.thermostat?.state?.lastStateReportedAt !== undefined &&
                                    props.thermostat?.state?.lastStateReportedAt !== null ?
                                    formatUTCDate(props.thermostat?.state?.lastStateReportedAt) : "No data"}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card">
                <div className="card-header p-1 pl-3">
                    <b>Mode</b>
                </div>
                <div className="card-body py-2">
                    <table className="table table-sm text-break">
                        <tbody>
                            <tr>
                                <th scope="row">Mode:</th>
                                <td>{modeFormat(props.thermostat?.mode)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Standby:</th>
                                <td>{props.thermostat?.mode?.isInStandby !== undefined ?
                                    props.thermostat?.mode?.isInStandby ? "Yes" : "No" : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Current setpoint temperature:</th>
                                <td>{props.thermostat?.mode !== undefined
                                    ? isAuto(props.thermostat?.mode) ? <>On Schedule</>
                                        : <>{printTemperature(getSetpoint(props.thermostat?.mode))}</>
                                    : "No data"}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card">
                <div className="card-header p-1 pl-3">
                    <b>Active schedule data</b>
                </div>
                <div className="card-body py-2">
                    <table className="table table-sm text-break">
                        <tbody>
                            <tr>
                                <th scope="row">Schedule ID:</th>
                                <td>{undefNullCheck(props.thermostat?.scheduleData?.scheduleId)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Schedule name:</th>
                                <td>{undefNullCheck(props.thermostat?.scheduleData?.scheduleName)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Next event start time:</th>
                                <td>{undefNullCheck(props.thermostat?.scheduleData?.nextEventStartTime)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Next event day of week:</th>
                                <td>{props.thermostat?.scheduleData?.nextEventDayOfWeek !== undefined ?
                                    DayOfWeek[props.thermostat?.scheduleData?.nextEventDayOfWeek] : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Next event setpoint:</th>
                                <td>{printTemperature(props.thermostat?.scheduleData?.nextEventSetPoint)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Current setpoint:</th>
                                <td>{printTemperature(props.thermostat?.scheduleData?.currentSetPoint)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card">
                <div className="card-header p-1 pl-3">
                    <b>DateTime settings current</b>
                </div>
                <div className="card-body py-2">
                    <table className="table table-sm text-break">
                        <tbody>
                            <tr>
                                <th scope="row">Current value:</th>
                                <td>{props.thermostat?.settings?.dateTimeCurrent?.currentValue !== undefined &&
                                    props.thermostat?.settings?.dateTimeCurrent?.currentValue !== null ?
                                    formatUTCDate(props.thermostat?.settings?.dateTimeCurrent?.currentValue) : "No data"}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
