import React, { useEffect, useState } from "react";
import { MessageBox, MessageBoxType, Spinner, withAuthProps, withCommonProps } from "../../Common";
import { connect, useStore } from "react-redux";
import { ApplicationState, IAuthProps, RequestState } from "../../../store";
import { actionCreators } from "../../../store/Audits/AuditDetails/actionCreators";
import { AuditDetails, AuditDetailState } from "../../../store/Audits/AuditDetails/state";
import { CommonState } from "../../../store/Common/state";
import { RouteComponentProps, useHistory } from "react-router";
import { Audit } from "../../../store/Audits/state";
import { undefNullCheckTr } from "../../../utils";
import { Connection as ConnectionDetail } from "./Connection";
import { EnergyInsights as EnergyInsightsDetail } from "./EnergyInsights";
import { FWUpdates as FWUpdatesDetail } from "./FWUpdates";
import { Linking as LinkingDetail } from "./Linking";
import { State as StateDetail } from "./State";
import { Button } from "reactstrap";
import { GrClose } from "react-icons/gr";

export enum AuditDetailCategories {
    Connection = "Connection",
    EnergyInsights = "Energy Insights",
    FWUpdates = "FWUpdates",
    Linking = "Linking",
    State = "State"
}

type AuditDetailProps =
    AuditDetailState &
    CommonState &
    typeof actionCreators &
    IAuthProps &
    RouteComponentProps;

const AuditDetailsExpand = (props: AuditDetailProps)=> {
    const [details, setDetails] = useState<Audit | undefined>();
    const [requestState, setRequestState] = useState<RequestState>(props.auditDetailRequestState);
    const [showMessage, setShowMessage] = useState<Boolean>(false);

    const state = useStore().getState;

    useEffect(() => {
        if (!details && props.auditDetailRequestState === RequestState.Succeeded) {
            setRequestState(props.auditDetailRequestState);
            setDetails(props.auditDetailSearchResult);
        }

        if (!details && props.auditDetailRequestState === RequestState.Failed) {
            setRequestState(props.auditDetailRequestState);
            setDetails({ level: "", message: "", timestamp: new Date() });
        }
    }, [props.auditDetailRequestState]);

    const addDataDisplay = (category: string) => {
        switch (category) {
            case AuditDetailCategories.Connection:
                return <ConnectionDetail {...details?.fields }/>;
            case AuditDetailCategories.EnergyInsights:
                return <EnergyInsightsDetail {...details?.fields }/>;
            case AuditDetailCategories.FWUpdates:
                return <FWUpdatesDetail {...details?.fields }/>;
            case AuditDetailCategories.Linking:
                return <LinkingDetail {...details?.fields }/>;
            case AuditDetailCategories.State:
                return <StateDetail {...details?.fields }/>;
            default:
                return <span></span>;
        }
    };

    const tableRowsGeneral = [
        (key: number) => undefNullCheckTr(details?.fields?.thermostatId, "Thermostat ID:", key),
        (key: number) => undefNullCheckTr(details?.fields?.userId, "User ID:", key),
        (key: number) => undefNullCheckTr(details?.fields?.logId, "Log ID:", key),
    ];

    return (
        requestState === RequestState.NotStarted || requestState === RequestState.Failed
            ?
            <MessageBox type={MessageBoxType.Error} title="Failed to search" description={"An error occurred when fetching log details"}/>
            : <div className="p-2" style={{ backgroundColor: "rgba(0, 0, 0, 0.03)" }}>
                {requestState === RequestState.InProgress ? <Spinner /> :
                    <><div className="card-columns m-2">
                        <div className="card">
                            <div className="card-header p-1 pl-3">
                                <b>General</b>
                            </div>
                            <div className="card-body py-2">
                                <table className="table table-sm text-break">
                                    <tbody>
                                        {tableRowsGeneral.map((item, idx) => item(idx))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {details?.fields?.category ?
                            addDataDisplay(details.fields.category)
                            : <></>}
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>{showMessage ?
                        <div className="border border-secondary rounded-lg p-2 m-2">
                            <button
                                onClick={() => setShowMessage(false)}
                                className="float-right btn btn-sm btn-default p-0">
                                <GrClose className="mt-n2" />
                            </button>
                            {details?.message}
                        </div>
                        : <Button className="p-1 m-2" onClick={() => setShowMessage(true)}>Show original message</Button>}</div></>
                }
            </div>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => ({ ...state.auditDetails }),
    actionCreators
)(AuditDetailsExpand as any)));