import { AppThunkAction } from "../..";
import { callApiGateway } from "../../../ApiGateway";
import SearchRequest from "../../search";
import { Audit, AuditDetailRequest } from "../state";
import { KnownAction } from "./actions";

export const actionCreators = {
    loadAuditDetail: () : AppThunkAction<KnownAction> => (dispatch, getState) =>{
        const state = getState();
        const category = state.auditDetails?.auditDetailSearchRequest?.selectedCategory;
        const id = state.auditDetails?.auditDetailSearchRequest?.selectedId;
        if (!(category && id)) {
            dispatch({ type: "REJECT_AUDIT_DETAILS" });
        }

        dispatch({ type: "REQUEST_AUDIT_DETAILS" });
        callApiGateway<SearchRequest, Audit>(`elastic/audits/${category}/${id}`, "GET")
            .then(response => {
                dispatch(
                    {
                        type: "RECEIVE_AUDIT_DETAILS",
                        searchResult: response,
                    });
            }
            ).catch((e) => {
                console.log(e);
                dispatch({ type: "REJECT_AUDIT_DETAILS" });
            });
    },

    setSelected: (request: AuditDetailRequest) : AppThunkAction<KnownAction> => (dispatch) =>{
        dispatch({ type: "SET_SELECTED_DETAILS", request });
    }
};