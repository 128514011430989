import React from "react";
import { AuditDetails } from "../../../store/Audits/AuditDetails/state";
import { undefNullCheckTr } from "../../../utils";
import { isTrFull } from "./State";

export const Connection = (props?: AuditDetails) => {
    const tableRowsConnection = [
        (key: number) => undefNullCheckTr(props?.connectionId, "Connection ID:", key),
        (key: number) => undefNullCheckTr(props?.distributorId, "Distributor ID:", key),
        (key: number) => undefNullCheckTr(props?.serialNumber, "Serial number:", key),
        (key: number) => undefNullCheckTr(props?.serverTime, "Server time:", key),
        /* TODO fine graining */
        (key: number) => undefNullCheckTr(props?.lastEventTimestamp, "Latest change/last message/last attempt:", key),
    ];

    const tableRowsMessage = [
        (key: number) => undefNullCheckTr(props?.messagePayload?.temperatureLimitMax, "Temperature limit max:", key),
        (key: number) => undefNullCheckTr(props?.messagePayload?.startTime, "Start time:", key),
        (key: number) => undefNullCheckTr(props?.messagePayload?.endTime, "End time:", key),
        (key: number) => undefNullCheckTr(props?.messagePayload?.changeTimestamp, "Change timestamp:", key),
    ];

    return (
        <>{isTrFull(tableRowsConnection) ? <div className="card">
            <div className="card-header p-1 pl-3">
                <b>Connection</b>
            </div>
            <div className="card-body py-2">
                <table className="table table-sm text-break">
                    <tbody>
                        {tableRowsConnection.map((item, idx) => item(idx))}
                    </tbody>
                </table>
            </div>
        </div> : <></>}
        {/* reducer can populate messagePayload with undefined timestamp, which falsely gives it a defined nature*/}
        {props?.messagePayload && props.messagePayload.startTime && isTrFull(tableRowsMessage) ? <div className="card">
            <div className="card-header p-1 pl-3">
                <b>Message</b>
            </div>
            <div className="card-body py-2">
                <table className="table table-sm text-break">
                    <tbody>
                        {tableRowsMessage.map((item, idx) => item(idx))}
                    </tbody>
                </table>
            </div>
        </div> : <></>}</>
    );
};