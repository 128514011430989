/* eslint-disable complexity */
import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { AuditDetailState } from "./state";
import { KnownAction } from "./actions";
import { stat } from "fs";
import { Timestamp } from "../state";

const unloadedState: AuditDetailState = {
    auditDetailRequestState: RequestState.NotStarted,
    cancelAuditDetailRequestState: RequestState.NotStarted,
    auditDetailSearchResult: undefined,
    auditDetailSearchRequest: undefined
};

export const reducer: Reducer<AuditDetailState> =
    (state: AuditDetailState | undefined, incomingAction: Action):
    AuditDetailState => {
        if (state === undefined) {
            return unloadedState;
        }

        const action = incomingAction as KnownAction;
        switch (action.type) {
            case "REQUEST_AUDIT_DETAILS":
                return {
                    ...state,
                    auditDetailRequestState: RequestState.InProgress,
                    cancelAuditDetailRequestState: RequestState.NotStarted
                };

            case "RECEIVE_AUDIT_DETAILS":
                return {
                    ...state,
                    auditDetailRequestState: RequestState.Succeeded,
                    auditDetailSearchResult: {
                        // converting from protobuf to Date
                        ...action.searchResult,
                        timestamp: "seconds" in action.searchResult.timestamp ?
                            new Date(action.searchResult.timestamp.seconds * 1000)  // Convert seconds to milliseconds
                            : action.searchResult.timestamp,
                        fields: {
                            ...action.searchResult.fields,
                            lastEventTimestamp: action.searchResult.fields?.lastEventTimestamp && "seconds" in action.searchResult.fields.lastEventTimestamp ?
                                new Date((action.searchResult.fields.lastEventTimestamp as Timestamp).seconds * 1000)  // Convert seconds to milliseconds
                                : undefined,
                            serverTime: action.searchResult.fields?.serverTime && "seconds" in action.searchResult.fields.serverTime ?
                                new Date((action.searchResult.fields.serverTime as Timestamp).seconds * 1000)  // Convert seconds to milliseconds
                                : undefined,

                            mode: {
                                ...action.searchResult.fields?.mode,
                                holdUntilEndTime: action.searchResult.fields?.mode?.holdUntilEndTime && "seconds" in action.searchResult.fields.mode?.holdUntilEndTime ?
                                    new Date((action.searchResult.fields.mode?.holdUntilEndTime as Timestamp).seconds * 1000)  // Convert seconds to milliseconds
                                    : undefined,
                            },

                            messagePayload: {
                                ...action.searchResult.fields?.messagePayload,
                                startTime: action.searchResult.fields?.messagePayload?.startTime && "seconds" in action.searchResult.fields.messagePayload?.startTime ?
                                    new Date((action.searchResult.fields.messagePayload?.startTime as Timestamp).seconds * 1000)  // Convert seconds to milliseconds
                                    : undefined,
                                endTime: action.searchResult.fields?.messagePayload?.endTime && "seconds" in action.searchResult.fields.messagePayload?.endTime ?
                                    new Date((action.searchResult.fields.messagePayload?.endTime as Timestamp).seconds * 1000)  // Convert seconds to milliseconds
                                    : undefined,
                            },

                            thermostatTelemetry: {
                                ...action.searchResult.fields?.thermostatTelemetry,
                                timestamp: action.searchResult.fields?.thermostatTelemetry?.timestamp && "seconds" in action.searchResult.fields.thermostatTelemetry?.timestamp ?
                                    new Date((action.searchResult.fields.thermostatTelemetry?.timestamp as Timestamp).seconds * 1000)  // Convert seconds to milliseconds
                                    : undefined,
                            },
                        }
                    }
                };

            case "REJECT_AUDIT_DETAILS":
                return {
                    ...state,
                    auditDetailRequestState: RequestState.Failed
                };

            case "SET_SELECTED_DETAILS":
                return {
                    ...state,
                    auditDetailSearchRequest: {
                        selectedId: action.request.selectedId,
                        selectedCategory: action.request.selectedCategory
                    }
                };

            default: return state;
        }
    };
