import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { extractAuthProps, IAuthProps } from "../store/authTypes";
import { signIn } from "../utils";
import { Page } from "./Common";
import { getPrivateLabelConfig } from "../PrivateLabel";

const Home = (props: IAuthProps) => {
    const privateLabel = getPrivateLabelConfig();

    const loginAnchor = !props.isAuthenticated
        ? <><button className="btn btn-link p-0 mt-n1" onClick={signIn}>Click here to login</button>.</>
        : null;

    return (
        <Page title="Home">
            <p className="mb-0">Welcome to the {privateLabel.adminToolTitle ? privateLabel.adminToolTitle : "Support Tool"}. {loginAnchor}</p>
        </Page>
    );
};

const mapStateToProps = (state: ApplicationState): IAuthProps => ({
    ...extractAuthProps(state.oidc)
});

export default connect(mapStateToProps)(Home);
