import React, { useCallback, useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { FaRegWindowClose } from "react-icons/fa";
import { connect } from "react-redux";
import { ApplicationState, RequestState } from "../../store";
import { actionCreators } from "../../store/Audits/actionCreators";
import { actionCreators as filterActionCreators } from "../../store/Audits/Filters/actionCreators";
import { actionCreators as detailActionCreators } from "../../store/Audits/AuditDetails/actionCreators";
import { ThermostatListItem } from "../../store/ThermostatsList/state";
import SearchRequest from "../../store/search";
import { Page, withAuthProps, withCommonProps } from "../Common";
import { TabButton } from "../Common/TabButton";
import { AuditDataTable } from "./AuditDataTable";
import AuditDetailsExpand from "./AuditDetails/AuditDetailsExpand";
import { AuditsPageProps } from "./ThermostatAuditsPage";
import { AggregationBucket, AuditFilters } from "../../store/Audits/Filters/state";
import { Audit, AuditDetailRequest } from "../../store/Audits/state";

const UserAuditsPage = (props: AuditsPageProps) => {
    const pageTitle = "User Audits";

    const [thermostats, setThermostats] = React.useState<ThermostatListItem[]>([]);
    const [selectedThermostats, setSelectedThermostats] = React.useState<ThermostatListItem[]>(thermostats);
    const [auditFilters, setAuditFilters] = useState<AggregationBucket[]>([]);

    const loadAuditsCallback = useCallback((request: SearchRequest) => {
        if (selectedThermostats.length) {
            const ids = selectedThermostats.length ? selectedThermostats.map(x => x.id) : thermostats.map(x => x.id);
            props.loadFilterAggregations("Thermostat", selectedThermostats.map(thermo => thermo.id), AuditFilters.Level);
            props.loadFilterAggregations("Thermostat", selectedThermostats.map(thermo => thermo.id), AuditFilters.Category);
            props.loadBulkedThermostatAudits(request, ids);
        } else {
            props.loadFilterAggregations("User", [props.match.params.id], AuditFilters.Level);
            props.loadFilterAggregations("User", [props.match.params.id], AuditFilters.Category);
            props.loadUserAudits(request, props.match.params.id);
        }
    }, [selectedThermostats, thermostats]);

    const loadAuditDetailCallback = (request: AuditDetailRequest) => {
        props.setSelected(request);
        props.loadAuditDetail();
    };

    useEffect(() => {
        setAuditFilters(props.filterAggregationResult ? props.filterAggregationResult.items : []);
    }, [props.filterAggregationResult]);

    const thermostatSelector = useRef<HTMLButtonElement>(null);

    // fetch multiple thermostats if audits are to be aggregated by user
    useEffect(() => {
        props.changeThermostatSearchRequest({ ...props.thermostatsSearchRequest, query: props.match.params.id });
        props.loadThermostats();
        // if the button is still conditionally rendered after 1.5 sec, it indicated the lack of thermostats
        setTimeout(() =>{
            if (thermostatSelector.current) {
                thermostatSelector.current.innerHTML = "No thermostats found!";
            }
        }, 1500);

    }, [props.match.params.id]);

    useEffect(() => {
        if (props.searchRequestState === RequestState.Succeeded) {
            const fetched = props.thermostatsResult?.items ?? [];
            setThermostats(fetched);
        }
    }, [props.searchRequestState]);

    const toggleThermostat = (thermostat: ThermostatListItem) => {
        if (selectedThermostats.map(x => x.id).includes(thermostat.id)) {
            const filtered = selectedThermostats.filter(t => t.id !== thermostat.id);
            setSelectedThermostats(filtered);
        } else {
            setSelectedThermostats([...selectedThermostats, thermostat]);
        }
    };

    const printId = (thermostat: ThermostatListItem) => (selectedThermostats.map(x => x.id).includes(thermostat.id)
        ? thermostat.name
        : thermostat.name.slice(0, 10).concat(thermostat.name.length > 10
            ? "..." : ""
        ));

    const selector = thermostats.length ? <>
        {thermostats.map(thermostat =>
            <TabButton style={{ width: "min-content", minWidth: "fit-content" }} text={printId(thermostat)}
                active={selectedThermostats.map(x => x.id).includes(thermostat.id)}
                onClick={() => toggleThermostat(thermostat)}/>)
        }
        <button className="btn ml-2 btn-danger" style={{ width: "min-content" }} type="button" onClick={() => setSelectedThermostats([])}><FaRegWindowClose size={18}/></button>
    </> :
        <button className="btn btn-primary" type="button" disabled ref={thermostatSelector}>
            <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
            Loading...
        </button>;
    return <IconContext.Provider value={{ className: "header-react-icons" }}>
        <Page id="thermostats-page" title={pageTitle}>
            <AuditDataTable
                state={props.auditSearchRequestState}
                response = {props.auditSearchResult}
                search={loadAuditsCallback}
                onExpand={loadAuditDetailCallback}
                filterAggregation={auditFilters}
                thermostatSelector={selector}
                displayText={"Audit"}
                pluralSuffix={"s"}
            />
        </Page>
    </IconContext.Provider>;
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => {
        return { ...state.audits,
            ...state.thermostatsList };
    },
    { ...actionCreators, ...filterActionCreators, ...detailActionCreators }
)(UserAuditsPage as any)));