/* eslint-disable max-params */
import React, { useState } from "react";
import DataTable, { createTheme, IDataTableColumn } from "react-data-table-component";
import { getDataTableTheme } from "../../../DataTableThemes";
import SearchRequest, { defaultSearchRequest, FilterTermMatchType, SearchResponse } from "../../../store/search";
import { RequestState } from "../../../store/sharedTypes";
import { maybePluralize } from "../../../utils";
import { MessageBox, MessageBoxType, Spinner } from "..";
import DataTableFilterSelector from "./DataTableFilterSelector";
import DataTableSearchField from "./DataTableSearchField";
import { number } from "yup";

export interface DataTableFilterSelectorDetails {
    field: string;
    values: string[];
    display?: string;
}

export interface SearchableDataTableProps<T> {
    state: RequestState;
    columns: IDataTableColumn[];
    response: SearchResponse<T> | undefined;
    search: (request: SearchRequest) => void;
    filters?: DataTableFilterSelectorDetails[];
    rowsPerPage?: number[];
    clearRows?: () => boolean;
    defaultSearchRequest?: SearchRequest;
    displayText?: string;
    pluralSuffix?: string;
    transparent?: boolean;
    hideShowAll?: boolean;
    contextComponent?: (selected: T[], clearRows: () => void) => React.ReactNode;
}

export const selectorSelectedFilters = (field: string, searchRequest: SearchRequest) => {
    return searchRequest.filter.terms
        .filter(term => term.field === field)
        .map(term => term.value);
};

export const selectorAddFilter = (field: string, value: string, searchRequest: SearchRequest, setSearchRequest: React.Dispatch<React.SetStateAction<SearchRequest>>) => {
    const updatedSearchRequest: SearchRequest = {
        ...searchRequest, filter: {
            ...searchRequest.filter,
            terms: [...searchRequest.filter.terms, {
                field: field,
                value: value,
                type: FilterTermMatchType.Equals // TODO: Allow to select this?
            }]
        }
    };
    setSearchRequest(updatedSearchRequest);
};

export const selectorRemoveFilter = (field: string, value: string, searchRequest: SearchRequest, setSearchRequest: React.Dispatch<React.SetStateAction<SearchRequest>>) => {
    const updatedSearchRequest: SearchRequest = {
        ...searchRequest, filter: {
            ...searchRequest.filter,
            terms: searchRequest.filter.terms
                .filter(term => term.field !== field || term.value !== value)
        }
    };
    setSearchRequest(updatedSearchRequest);
};

export const tableChangeLimit = (limit: number, searchRequest: SearchRequest, setSearchRequest: React.Dispatch<React.SetStateAction<SearchRequest>>) => {
    const updatedSearchRequest: SearchRequest = {
        ...searchRequest, page: {
            ...searchRequest.page,
            size: limit
        }
    };
    setSearchRequest(updatedSearchRequest);
};

export const tableChangePage = (page: number, searchRequest: SearchRequest, setSearchRequest: React.Dispatch<React.SetStateAction<SearchRequest>>) => {
    const updatedSearchRequest: SearchRequest = {
        ...searchRequest, page: {
            ...searchRequest.page,
            number: page
        }
    };
    setSearchRequest(updatedSearchRequest);
};

export const tableChangeSorting = (field: string, descending: boolean, searchRequest: SearchRequest, setSearchRequest: React.Dispatch<React.SetStateAction<SearchRequest>>) => {
    const updatedSearchRequest: SearchRequest = {
        ...searchRequest, order: {
            terms: [{
                field: field,
                desc: descending
            }]
        }
    };
    setSearchRequest(updatedSearchRequest);
};

export const tableCreateTheme = () => createTheme("solarized", {
    text: {
        primary: "black",
    },
    background: {
        default: "rgba(0,0,0,0)",
    }
});

export const SearchableDataTable = <T extends any>(props: SearchableDataTableProps<T>) => {
    const [searchRequest, setSearchRequest] = useState<SearchRequest>(props.defaultSearchRequest ?? defaultSearchRequest);
    const [selected, setSelected] = useState<T[]>([]);
    const [clearRows, setClearRows] = useState<boolean>(props.clearRows ?? false);
    const [query, setQuery] = useState<string>(props.defaultSearchRequest?.query ?? "");

    const rowsPerPage = props.rowsPerPage ?? [10, 20, 50, 100, 200, 500];
    const displayText = props.displayText ?? "result";
    const pluralSuffix = props.pluralSuffix ?? "s";

    const { search } = props;

    const selectedFilters = (field: string) => selectorSelectedFilters(field, searchRequest);
    const addFilter = (field: string, value: string) => selectorAddFilter(field, value, searchRequest, setSearchRequest);
    const removeFilter = (field: string, value: string) => selectorRemoveFilter(field, value, searchRequest, setSearchRequest);

    const changeLimit = (limit: number) => tableChangeLimit(limit, searchRequest, setSearchRequest);
    const changePage = (page: number) => tableChangePage(page, searchRequest, setSearchRequest);
    const changeSorting = (field: string, descending: boolean) => tableChangeSorting(field, descending, searchRequest, setSearchRequest);

    React.useEffect(() => {
        search(searchRequest);
    }, [search, searchRequest]);

    tableCreateTheme();

    return (
        <div className="d-flex flex-column">
            <div className="d-flex" style={{ maxWidth: "800px" }}>
                <DataTableSearchField
                    query={query}
                    displayText={`${displayText}${pluralSuffix}`}
                    disableButton={props.state === RequestState.InProgress}
                    setQuery={(q) => setQuery(q)}
                    search={() => setSearchRequest({ ...searchRequest, query: query })} />
            </div>

            <div className="d-flex m-2" style={{ maxWidth: "800px" }}>
                {props.filters?.map(filter =>
                    <DataTableFilterSelector
                        key={filter.field}
                        field={filter.field}
                        selected={selectedFilters(filter.field)}
                        values={filter.values}
                        disableButton={props.state === RequestState.InProgress}
                        addFilter={addFilter}
                        removeFilter={removeFilter}
                        display={filter.display} />
                )}
            </div>
            {props.state === RequestState.Failed ? (
                <MessageBox type={MessageBoxType.Error} title="Failed to search" description={`An error occurred when searching ${displayText}${pluralSuffix}`}>
                    <button
                        className="btn btn-primary mt-3"
                        onClick={() => search(searchRequest)}>Try again</button>
                    <button
                        className="btn btn-primary mt-3 ml-3"
                        onClick={() => search(defaultSearchRequest)}>Reset search</button>
                </MessageBox>
            ) :
                (
                    <DataTable
                        // Options
                        keyField={"id"}
                        noHeader={!props.response}
                        title={!props.response ? null : `Found ${maybePluralize(props.response.total, displayText, pluralSuffix)}`}
                        columns={props.columns}
                        data={!props.response ? [] : props.response.items}
                        noDataComponent={<p> </p>}

                        // Pagination
                        pagination
                        paginationServer
                        paginationPerPage={searchRequest.page.size}
                        paginationRowsPerPageOptions={rowsPerPage}
                        paginationTotalRows={!props.response ? 0 : props.response.total}
                        onChangeRowsPerPage={size => changeLimit(size)}
                        // page index in API is zero based but in react-data-table-component it starts from 1
                        onChangePage={page => changePage(page - 1)}

                        // Sorting
                        onSort={(column, direction) => changeSorting(column.selector as string, direction === "desc")}
                        sortServer

                        // Selectable rows
                        clearSelectedRows={clearRows}
                        onSelectedRowsChange={({ selectedRows }) => {
                            setSelected(selectedRows);
                            setClearRows(false);
                        }}
                        selectableRows
                        selectableRowsHighlight
                        selectableRowsNoSelectAll={props.hideShowAll}
                        // Loading/progress
                        progressPending={props.state === RequestState.InProgress}
                        progressComponent={<Spinner />}

                        // Actions
                        contextComponent={props.contextComponent ? props.contextComponent(selected, () => setClearRows(true)) : <p></p>}

                        // Theme
                        theme={props.transparent ? "solarized" : getDataTableTheme()}
                    />)}
        </div>
    );
};

export default SearchableDataTable;