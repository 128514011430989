import * as React from "react";
import { DstType, FloorType, Screensaver, SensorApplication, TemperatureUnit } from "../../store/ThermostatDetails/state";
import { formatUTCDate, maybePluralize, printTemperature, undefNullCheck } from "../../utils";
import { ThermostatDetailsProps } from "./ThermostatDetailsPage";

// eslint-disable-next-line complexity
export const OtherSettingsTab = (props: ThermostatDetailsProps) => {

    return (
        <div>
            <div className="card">
                <div className="card-header p-1 pl-3">
                    <b>Regulation settings</b>
                </div>
                <div className="card-body py-2">
                    <table className="table table-sm text-break">
                        <tbody>
                            <tr>
                                <th scope="row">Sensor application:</th>
                                <td>{props.thermostat?.settings?.regulation?.sensorApplication !== undefined ?
                                    SensorApplication[props.thermostat?.settings?.regulation?.sensorApplication] : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Floor type:</th>
                                <td>{props.thermostat?.settings?.regulation?.floorType !== undefined ?
                                    FloorType[props.thermostat?.settings?.regulation?.floorType] : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Max. setpoint:</th>
                                <td>{printTemperature(props.thermostat?.settings?.regulation?.maxSetpoint)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Min. setpoint:</th>
                                <td>{printTemperature(props.thermostat?.settings?.regulation?.minSetpoint)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Temperature limit max.:</th>
                                <td>{printTemperature(props.thermostat?.settings?.regulation?.temperatureLimitMax)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Temperature limit min.:</th>
                                <td>{printTemperature(props.thermostat?.settings?.regulation?.temperatureLimitMin)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Floor protection max.:</th>
                                <td>{printTemperature(props.thermostat?.settings?.regulation?.floorProtectionMax)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Floor protection min.:</th>
                                <td>{printTemperature(props.thermostat?.settings?.regulation?.floorProtectionMin)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Frost protection:</th>
                                <td>{props.thermostat?.settings?.regulation?.frostProtectionEnabled !== undefined ?
                                    props.thermostat.settings.regulation.frostProtectionEnabled ? "Enabled" : "Disabled" : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Frost protection temperature:</th>
                                <td>{printTemperature(props.thermostat?.settings?.regulation?.frostProtectionTemperature)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Changed:</th>
                                <td>{props.thermostat?.settings?.regulation?.changeTimestamp !== undefined &&
                                    props.thermostat?.settings?.regulation?.changeTimestamp !== null ?
                                    formatUTCDate(props.thermostat?.settings?.regulation?.changeTimestamp) : "No data"}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card">
                <div className="card-header p-1 pl-3">
                    <b>Display settings</b>
                </div>
                <div className="card-body py-2">
                    <table className="table table-sm text-break">
                        <tbody>
                            <tr>
                                <th scope="row">Screen lock:</th>
                                <td>{props.thermostat?.settings?.display?.screenLockEnabled !== undefined ?
                                    props.thermostat.settings.display.screenLockEnabled ? "Enabled" : "Disabled" : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Brightness (normal):</th>
                                <td>{undefNullCheck(props.thermostat?.settings?.display?.brightnessNormal)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Brightness (screensaver):</th>
                                <td>{undefNullCheck(props.thermostat?.settings?.display?.brightnessScreensaver)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Screensaver:</th>
                                <td>{props.thermostat?.settings?.display?.screensaver !== undefined ?
                                    Screensaver[props.thermostat?.settings?.display?.screensaver] : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Display:</th>
                                <td>{props.thermostat?.settings?.display?.displayOn !== undefined ?
                                    props.thermostat.settings.display.displayOn ? "On" : "Off" : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Temperature unit:</th>
                                <td>{props.thermostat?.settings?.display?.temperatureUnit !== undefined ?
                                    TemperatureUnit[props.thermostat?.settings?.display?.temperatureUnit] : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Changed:</th>
                                <td>{props.thermostat?.settings?.display?.changeTimestamp !== undefined &&
                                    props.thermostat?.settings?.display?.changeTimestamp !== null ?
                                    formatUTCDate(props.thermostat?.settings?.display?.changeTimestamp) : "No data"}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card">
                <div className="card-header p-1 pl-3">
                    <b>Heating settings</b>
                </div>
                <div className="card-body py-2">
                    <table className="table table-sm text-break">
                        <tbody>
                            <tr>
                                <th scope="row">Floor load:</th>
                                <td>{undefNullCheck(props.thermostat?.settings?.heating?.floorLoad)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Floor sensor type:</th>
                                <td>{undefNullCheck(props.thermostat?.settings?.heating?.floorSensorType)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Adaptive heating:</th>
                                <td>{props.thermostat?.settings?.heating?.isAdaptiveHeatingEnabled !== undefined ?
                                    props.thermostat.settings.heating.isAdaptiveHeatingEnabled ? "Enabled" : "Disabled" : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Open window detection:</th>
                                <td>{props.thermostat?.settings?.heating?.isOpenWindowDetectionEnabled !== undefined ?
                                    props.thermostat.settings.heating.isOpenWindowDetectionEnabled ? "Enabled" : "Disabled" : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Floor sensor offset:</th>
                                <td>{undefNullCheck(props.thermostat?.settings?.heating?.floorSensorOffset)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Room sensor offset:</th>
                                <td>{undefNullCheck(props.thermostat?.settings?.heating?.roomSensorOffset)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Changed:</th>
                                <td>{props.thermostat?.settings?.heating?.changeTimestamp !== undefined &&
                                    props.thermostat?.settings?.heating?.changeTimestamp !== null ?
                                    formatUTCDate(props.thermostat?.settings?.heating?.changeTimestamp) : "No data"}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card">
                <div className="card-header p-1 pl-3">
                    <b>Floor sensor settings</b>
                </div>
                <div className="card-body py-2">
                    <table className="table table-sm text-break">
                        <tbody>
                            <tr>
                                <th scope="row">Value 15 degree:</th>
                                <td>{undefNullCheck(props.thermostat?.settings?.floorSensor?.value15Deg)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Value 20 degree:</th>
                                <td>{undefNullCheck(props.thermostat?.settings?.floorSensor?.value20Deg)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Value 25 degree:</th>
                                <td>{undefNullCheck(props.thermostat?.settings?.floorSensor?.value25Deg)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Value 30 degree:</th>
                                <td>{undefNullCheck(props.thermostat?.settings?.floorSensor?.value30Deg)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Changed:</th>
                                <td>{props.thermostat?.settings?.floorSensor?.changeTimestamp !== undefined &&
                                    props.thermostat?.settings?.floorSensor?.changeTimestamp !== null ?
                                    formatUTCDate(props.thermostat?.settings?.floorSensor?.changeTimestamp) : "No data"}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card">
                <div className="card-header p-1 pl-3">
                    <b>DateTime settings</b>
                </div>
                <div className="card-body py-2">
                    <table className="table table-sm text-break">
                        <tbody>
                            <tr>
                                <th scope="row">DST:</th>
                                <td>{props.thermostat?.settings?.dateTime?.dst !== undefined ?
                                    DstType[props.thermostat?.settings?.dateTime?.dst] : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Timezone:</th>
                                <td>{props.thermostat?.settings?.dateTime?.timeZoneDelay ? maybePluralize(props.thermostat?.settings?.dateTime?.timeZoneDelay, "hour", "s") : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Changed:</th>
                                <td>{props.thermostat?.settings?.dateTime?.changeTimestamp !== undefined &&
                                    props.thermostat?.settings?.dateTime?.changeTimestamp !== null ?
                                    formatUTCDate(props.thermostat?.settings?.dateTime?.changeTimestamp) : "No data"}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
