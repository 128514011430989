import React from "react";
import { AuditDetails } from "../../../store/Audits/AuditDetails/state";
import { undefNullCheckTr } from "../../../utils";
import { isTrFull } from "./State";

export const FWUpdates = (props?: AuditDetails) => {
    const tableRowsFWUpdate = [
        (key: number) => undefNullCheckTr(props?.lastEventTimestamp, "Last event:", key),
        (key: number) => undefNullCheckTr(props?.firmwareId, "Firmware ID:", key),
        (key: number) => undefNullCheckTr(props?.expectedSoftwareVersion, "Expected version:", key),
        (key: number) => undefNullCheckTr(props?.receivedSoftwareVersion, "Received version:", key),
    ];

    return (
        <>{isTrFull(tableRowsFWUpdate) ?
            <><div className="card">
                <div className="card-header p-1 pl-3">
                    <b>Firmware Updates</b>
                </div>
            </div><div className="card-body py-2">
                <table className="table table-sm text-break">
                    <tbody>
                        {tableRowsFWUpdate.map((item, idx) => item(idx))}
                    </tbody>
                </table>
            </div></> : <></>}
        </>);
};