import React, { Children, ReactChild, ReactNode } from "react";
import { AuditDetails } from "../../../store/Audits/AuditDetails/state";
import { undefNullCheckTr } from "../../../utils";
import { number } from "yup";

export const isTrFull = (trList: ((key: number) => React.ReactNode)[]) => {
    const valid = trList.reduce((acc, cur) => {
        const item = cur(0);
        return acc || (React.isValidElement(item) && !!item.props.children);
    }, false);
    return valid;
};

export const State = (props?: AuditDetails) => {
    const tableRowsState = [
        (key: number) => undefNullCheckTr(props?.lastEventTimestamp, "Last event:", key),
        (key: number) => undefNullCheckTr(props?.distributorId, "Connection ID:", key),
        (key: number) => undefNullCheckTr(props?.buildingId, "Distributor ID:", key),
        (key: number) => undefNullCheckTr(props?.zoneId, "Serial number:", key),
        (key: number) => undefNullCheckTr(props?.scheduleId, "Schedule ID:", key),
        (key: number) => undefNullCheckTr(props?.previousUserId, "Previous user ID:", key),
    ];

    const tableRowsMode = [
        (key: number) => undefNullCheckTr(props?.mode?.scheduleEventId, "Schedule event ID:", key),
        (key: number) => undefNullCheckTr(props?.mode?.scheduleNextEventId, "Next schedule event ID:", key),
        (key: number) => undefNullCheckTr(props?.mode?.manualSetpoint, "Manual setpoint:", key),
        (key: number) => undefNullCheckTr(props?.mode?.holdUntilSetpoint, "Hold until setpoint:", key),
        (key: number) => undefNullCheckTr(props?.mode?.holdUntilEndTime, "Hold until ending:", key),
        (key: number) => undefNullCheckTr(props?.mode?.holdIsPermanent, "Is hold permanent:", key),
        (key: number) => undefNullCheckTr(props?.mode?.fallbackMode, "Fallback mode:", key),
    ];

    return (
        <>{isTrFull(tableRowsState) ? <div className="card">
            <div className="card-header p-1 pl-3">
                <b>State</b>
            </div>
            <div className="card-body py-2">
                <table className="table table-sm text-break">
                    <tbody>
                        {tableRowsState.map((item, idx) => item(idx))}
                    </tbody>
                </table>
            </div>
        </div> : <></>}
        {isTrFull(tableRowsMode) ? <div className="card">
            <div className="card-header p-1 pl-3">
                <b>Mode</b>
            </div>
            <div className="card-body py-2">
                <table className="table table-sm text-break">
                    <tbody>
                        {tableRowsMode.map((item, idx) => item(idx))}
                    </tbody>
                </table>
            </div>
        </div> : <></>}</>
    );
};