import { AppThunkAction } from "..";
import { callApiGateway } from "../../ApiGateway";
import SearchRequest, { BulkSearchRequest } from "../search";
import { KnownAction } from "./actions";
import { Audit, AuditsSearchResponse } from "./state";
import * as thermostat from "../ThermostatsList/actionCreators";
import { ThermostatsSearchRequest } from "../ThermostatsList/state";

export const actionCreators = {
    loadBulkedThermostatAudits: (request: SearchRequest, ids: string[]) : AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "REQUEST_SEARCH_AUDITS", searchRequest: request });
        callApiGateway<BulkSearchRequest, AuditsSearchResponse>("elastic/thermostats", "POST", { ...request, ids })
            .then(response => dispatch({ type: "RECEIVE_SEARCH_AUDITS", searchResult: response }))
            .catch(() => dispatch({ type: "REJECT_SEARCH_AUDITS" }));
    },
    loadThermostatAudits: (request: SearchRequest, id: string) : AppThunkAction<KnownAction> => (dispatch) =>{
        dispatch({ type: "REQUEST_SEARCH_AUDITS", searchRequest: request });
        callApiGateway<SearchRequest, AuditsSearchResponse>(`elastic/thermostat/${id}`, "POST", request)
            .then(response => dispatch({ type: "RECEIVE_SEARCH_AUDITS", searchResult: response }))
            .catch(() => dispatch({ type: "REJECT_SEARCH_AUDITS" }));
    },

    loadAuditAggregation: (request: SearchRequest, id: string) : AppThunkAction<KnownAction> => (dispatch) =>{
        dispatch({ type: "REQUEST_SEARCH_AUDITS", searchRequest: request });
        callApiGateway<SearchRequest, AuditsSearchResponse>(`elastic/thermostat/${id}/aggregation`, "POST", request)
            .then(response => dispatch({ type: "RECEIVE_AUDIT_AGGREGATION", searchResult: response }))
            .catch(() => dispatch({ type: "REJECT_SEARCH_AUDITS" }));
    },

    loadUserAudits: (request: SearchRequest, id: string) : AppThunkAction<KnownAction> => (dispatch) =>{
        dispatch({ type: "REQUEST_SEARCH_AUDITS", searchRequest: request });
        callApiGateway<SearchRequest, AuditsSearchResponse>(`elastic/user/${id}`, "POST", request)
            .then(response => dispatch({ type: "RECEIVE_SEARCH_AUDITS", searchResult: response }))
            .catch(() => dispatch({ type: "REJECT_SEARCH_AUDITS" }));
    },

    changeThermostatSearchRequest: (request: ThermostatsSearchRequest) => thermostat.actionCreators.changeThermostatsSearchRequest(request),
    loadThermostats: () => thermostat.actionCreators.loadThermostats()
};