import React, { useState } from "react";
import DataTable, { createTheme, IDataTableColumn } from "react-data-table-component";
import { FaSearch } from "react-icons/fa";
import { Spinner } from "reactstrap";
import { RequestState } from "../../../../store/sharedTypes";
import { DefaultSettings } from "../../../../store/ThermostatsList/reducer";
import { ThermostatListItem, ThermostatsSearchRequest, ThermostatsSearchResponse } from "../../../../store/ThermostatsList/state";
import { maybePluralize } from "../../../../utils";
import { tableCreateTheme } from "../../../Common/SearchableDataTable/SearchableDataTable";

export interface ThermostatSelectionProps {
    searchRequest: ThermostatsSearchRequest | undefined;
    searchResult: ThermostatsSearchResponse | undefined;
    searchState: RequestState;
    changeSearch: (request: ThermostatsSearchRequest) => void;
    search: () => void;
    selected: string[];
    setSelected: (selected: string[]) => void;
}

export const ThermostatSelection = (props: ThermostatSelectionProps) => {
    const [query, setQuery] = useState<string | undefined>(props.searchRequest?.query ?? "");
    const search = () => {
        props.changeSearch({ ...props.searchRequest, query: query });
        props.search();
    };

    const [clearRows, setClearRows] = React.useState(false);

    const createColumns = () => {
        const statusFormat = (thermostat: ThermostatListItem): string => {
            return thermostat.isConnected ? "Connected" : "Disconnected";
        };

        const columns: IDataTableColumn[] = [
            {
                name: "SW Version",
                selector: "softwareVersion",
                sortable: true,
                compact: true
            },
            {
                name: "Email",
                selector: "email",
                sortable: true,
                compact: true
            },
            {
                name: "Serial No.",
                selector: "serialNumber",
                sortable: true,
                compact: true
            },
            {
                name: "WiFi",
                selector: "isConnected",
                format: statusFormat,
                sortable: true,
                compact: true,
                maxWidth: "110px"
            },
            {
                name: "WiFi Version",
                selector: "wifiSoftwareVersion",
                sortable: true,
                compact: true
            },
            {
                name: "HW Version",
                selector: "hardwareVersion",
                sortable: true,
                compact: true
            }
        ];

        return columns;
    };

    const getOrderName = (columnName: string): string => {
        switch (columnName) {
            case "isConnected":
                return "isOnline";

            case "email":
                return "userEmail";

            case "softwareVersion":
                return "thermostatSoftwareVersion";

            case "wifiSoftwareVersion":
                return "thermostatWifiSoftwareVersion";

            case "hardwareVersion":
                return "thermostatHardwareVersion";
        }
        return columnName;
    };

    tableCreateTheme();

    return (
        <div>
            <div className="input-group mb-3">
                {/* Search field */}
                <input
                    type="text"
                    name="query"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    className="form-control"
                    placeholder="Search thermostats" />

                <div className="input-group-append">
                    {/* Search button */}
                    <button
                        disabled={props.searchState === RequestState.InProgress}
                        id="search-btn"
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => search()}>
                        <FaSearch className="mt-n1" />
                    </button>
                </div>
            </div>

            <DataTable
                // Options
                keyField={"id"}
                theme={"solarized"}
                noHeader={!props.searchResult}
                title={!props.searchResult ? null : `Found ${maybePluralize(props.searchResult.total, "thermostat")}`}
                columns={createColumns()}
                data={!props.searchResult ? [] : props.searchResult.items}
                noDataComponent={<p></p>}

                // Pagination
                pagination
                paginationServer
                paginationPerPage={props.searchRequest!.limit}
                paginationRowsPerPageOptions={DefaultSettings.paginationRowsPerPageOptions}
                paginationTotalRows={!props.searchResult ? 0 : props.searchResult.total}
                onChangeRowsPerPage={size => {
                    props.changeSearch({ limit: size });
                    props.search();
                }}
                // page index in API is zero based but in react-data-table-component it starts from 1
                onChangePage={page => {
                    props.changeSearch({ offset: (page - 1) * (props!.searchRequest!.limit || DefaultSettings.defaultPageSize) });
                    props.search();
                }}

                // Sorting
                defaultSortAsc={DefaultSettings.defaultSortAsc}
                defaultSortField={DefaultSettings.defaultSortField} // without this first render doesn't show default sort column and also sort order is stuck in default
                onSort={(column, sortDirection) => {
                    props.changeSearch({
                        orderBy: getOrderName(column.selector as string),
                        orderDescending: sortDirection === "desc"
                    });
                    props.search();
                }}
                sortServer

                clearSelectedRows={clearRows}
                onSelectedRowsChange={({ selectedRows }) => {
                    props.setSelected(selectedRows.map(r => r.id));
                    setClearRows(false);
                }}
                selectableRows
                selectableRowsHighlight

                // Loading/progress
                progressPending={props.searchState === RequestState.InProgress}
                progressComponent={<Spinner />}
            />
        </div>
    );
};

export default ThermostatSelection;