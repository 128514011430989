import { Log, } from "oidc-client";
import { OidcConfig } from "./AppConfig";

const scopes = [
    "openid",
    "role",
    "profile",
    "email",
    "address",
    "offline_access",
    "agw.profile", // <-- access token will contain profile data for API
    "agw.users",
    "agw.thermostats",
    "agw.firmware",
    "agw.private-labels",
    "agw.distributors",
    "agw.emails",
    "agw.buildings",
    "agw.zones",
    "agw.schedules",
    "agw.elastic-audits"
];

export type OidcLogConfig = {
    level: number,
    logger: Console
}

export interface OidcComponentConfig extends OidcConfig {
    redirect_uri: string,
    post_logout_redirect_uri: string,
    silent_redirect_uri: string,
    response_type: string,
    scope: string,
    automaticSilentRenew: boolean,
    filterProtocolClaims: boolean,
    loadUserInfo: boolean,
    monitorSession: boolean
}

export type OidcConfiguration = {
    log: OidcLogConfig,
    config: OidcComponentConfig
}

const oidcConfiguration: OidcConfiguration = {
    log: {
        level: Log.DEBUG,
        logger: console
    },
    config: {
        client_id: "SPECIFY_CLIENT_ID", // <-- override with value from server-side's config.json
        authority: "SPECIFY_AUTHORITY", // <-- override with value from server-side's config.json
        redirect_uri: "/authentication/callback",
        silent_redirect_uri: "/authentication/silent_callback",
        post_logout_redirect_uri: "/",
        response_type: "code",
        scope: scopes.join(" "),
        automaticSilentRenew: true,
        filterProtocolClaims: true,
        loadUserInfo: false, // <-- our IS4 already returns all claims in id_token, no need to load them via ~/connect/userinfo endpoint
        monitorSession: true
    },
};

export default oidcConfiguration;
