import * as React from "react";
import { IconContext } from "react-icons";
import { FaDownload, FaHeartbeat, FaListAlt, FaRegQuestionCircle, FaEnvelopeOpenText, FaSitemap, FaUsersCog, FaStar, FaMedal, FaShieldAlt } from "react-icons/fa";
import { ConnectedProps, connect } from "react-redux";
import { NavLink as RouterNavLink } from "react-router-dom";
import { ApplicationState } from "../store";
import { extractAuthProps, IAuthProps } from "../store/authTypes";
import "./SideMenu.css";
import useResizeObserver from "@react-hook/resize-observer";
import { actionCreators } from "../store/Common/actionCreators";
import { getUserManager } from "../utils";

interface SideMenuButttonProps {
    to: string;
    title: string;
    children?: React.ReactNode;
    exact?: boolean;
    last?: boolean;
}

const SideMenuButton = (props: SideMenuButttonProps) => {
    return (
        <>
            <RouterNavLink
                exact={props.exact}
                className="p-2 d-block text-center text-decoration-none"
                title={props.title}
                to={props.to}>
                <div className="mx-auto">
                    {props.children}
                    <small className="mb-0 d-block">{props.title}</small>
                </div>
            </RouterNavLink>
            {!props.last ? <hr className="m-2" /> : null}
        </>
    );
};

const SideMenuButtonExternal = (props: SideMenuButttonProps) => {
    return (
        <>
            <a className="p-2 d-block text-center text-decoration-none" target="blank" href={props.to}>
                <div className="mx-auto">
                    {props.children}
                    <small className="mb-0 d-block">{props.title}</small>
                </div>
            </a>
            {!props.last ? <hr className="m-2" /> : null}
        </>
    );
};

function throttle(resize: (...args: any[]) => void, delay: number) {
    let timer = 0;
    return function(this: unknown, ...args: any[]) {
        clearTimeout(timer);
        timer = window.setTimeout(() => {
            resize.apply(this, args);
        }, delay);
    };
}

const SideMenu = (props: IAuthProps & PropsFromRedux) => {
    const authorityUrl : string = getUserManager().settings.authority!;
    const observedSide = React.useRef<HTMLElement>(null);

    useResizeObserver(observedSide, throttle((entries: any) => {
        const { width } = entries.target.getBoundingClientRect();
        props.setWidth(width);
    }, 100));

    return (
        <IconContext.Provider value={{ className: "side-bar-react-icons" }}>
            <nav ref={observedSide} className="bg-white rounded-lg shadow py-3">
                {props.isAuthenticated && <>
                    <SideMenuButton to="/users" title="User Management"><FaUsersCog /></SideMenuButton>
                    <SideMenuButton to="/thermostats" title="Thermostats"><FaListAlt /></SideMenuButton>
                    {props.isAdmin && <SideMenuButton to="/firmware" title="Firmware"><FaDownload /></SideMenuButton>}
                    {props.isAdmin && <SideMenuButton to="/privateLabels" title="Private Labels"><FaStar /></SideMenuButton>}
                    {(props.isAdmin || props.hasMoreThanOneDistributor) && <SideMenuButton to="/distributors" title="Distributors"><FaMedal /></SideMenuButton>}
                    <SideMenuButton to="/email-templates" title="Email Templates"><FaEnvelopeOpenText /></SideMenuButton>
                    {/* <SideMenuButton to="/connectivity" title="Connectivity"><FaSitemap /></SideMenuButton> */}
                </>}

                {/* <SideMenuButton to="/faq" title="FAQ"><FaRegQuestionCircle /></SideMenuButton> */}

                <SideMenuButtonExternal to={authorityUrl.replace("identity", "health")} title="System Health"><FaHeartbeat /></SideMenuButtonExternal>
                {props.isAuthenticated && <SideMenuButtonExternal to={authorityUrl} title="Identity" last><FaShieldAlt/></SideMenuButtonExternal>}
            </nav>
        </IconContext.Provider>
    );
};

const mapStateToProps = (state: ApplicationState) => {
    return ({
        ...extractAuthProps(state.oidc),
        hasMoreThanOneDistributor: !!state.common?.distributors && state.common?.distributors.length > 1
    });
};

const connector = connect(mapStateToProps, actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SideMenu);
